import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "温泉浸かりながら開発（開発合宿@おんやど恵）",
  "date": "2016-05-30T07:56:40.000Z",
  "slug": "entry/2016/05/30/165640",
  "tags": ["medley"],
  "hero": "./2016_05_30.png",
  "heroAlt": "合宿"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`文責：徐聖博（`}<a parentName="p" {...{
        "href": "https://www.target-darts.jp/shopdetail/000000000022/"
      }}>{`ダーツプロ`}</a>{`）`}</p>
    <h1>{`日常業務で後回しになっている課題を合宿で`}</h1>
    <p>{`メドレーには開発本部という、エンジニア・デザイナー全員が所属する組織があります。`}</p>
    <p>{`蓋を開けてみると、チームに分かれていて、各々のプロダクトを担当しています。`}</p>
    <p>{`そのため、社内のシステム整備や、プロダクトを横断してやるようなプロジェクトは日常的にやる機会がありません。`}</p>
    <p>{`今回の合宿では、各プロダクトのメンバーをシャッフルし、4 チームに別れ各々の課題を解いてもらう形式で合宿を行いました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530133300.png",
        "alt": "f:id:yamadagenki:20160530133300p:plain"
      }}></img></p>
    <p>{`プロダクトメンバーをシャッフルして 4 チームに`}</p>
    <h1>{`ということで、湯河原に合宿しに来ました`}</h1>
    <p>{`今回の合宿地は、神奈川県湯河原。`}</p>
    <p>{`都内から、特急乗って 1 時間ちょっとなので、立地的には十分近い場所。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530105740.png",
        "alt": "f:id:yamadagenki:20160530105740p:plain"
      }}></img></p>
    <p>{`意外と都内から近くてびっくり`}</p>
    <h1>{`駅からバスで約 7 分、そこは「理想郷」であった`}</h1>
    <p>{`駅からバスに約 7 分乗り、「理想郷」という名のバス停で下車。`}</p>
    <p>{`今回の合宿地であるおんやど恵は、バス停のすぐ目の前にありました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530110057.jpg",
        "alt": "f:id:yamadagenki:20160530110057j:plain"
      }}></img></p>
    <p>{`無事に宿について喜ぶ新居さん`}</p>
    <h1>{`早速、開発開始`}</h1>
    <p>{`今回の合宿は事前に課題を設定し、4 チームに分けそれぞれ開発を進めていくスタイル。`}</p>
    <p>{`開発中は、普段あまり関わりがないメンバーとも和気あいあいと、チームで開発を進めました。`}</p>
    <p>{`1 日目は各チーム深夜まで開発が続きました。`}</p>
    <p>{`みんなさん開発好きですね。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530110939.jpg",
        "alt": "f:id:yamadagenki:20160530110939j:plain"
      }}></img></p>
    <p>{`普段と違うメンバーでやる開発はとても学ぶことが多かったようです`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530130713.jpg",
        "alt": "f:id:yamadagenki:20160530130713j:plain"
      }}></img></p>
    <p>{`お互いのもつ知識を共有しつつ、相談しながら開発`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530130759.jpg",
        "alt": "f:id:yamadagenki:20160530130759j:plain"
      }}></img></p>
    <p>{`ホワイトボードを使ったディスカッションも白熱しました`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530130730.jpg",
        "alt": "f:id:yamadagenki:20160530130730j:plain"
      }}></img></p>
    <p>{`会社ではできないこんな体勢での開発も合宿なら可能!!`}</p>
    <h1>{`なんといっても温泉と美味しいごはん`}</h1>
    <p>{`おんやど恵にしてよかったなと思ったのは、なんと言っても温泉とご飯でした。`}</p>
    <p>{`24 時間入れる足湯から、大浴場の露天風呂といい最高でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530111435.jpg",
        "alt": "f:id:yamadagenki:20160530111435j:plain"
      }}></img></p>
    <p>{`足湯。タオルがあるので手ぶらでいけます`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530111536.jpg",
        "alt": "f:id:yamadagenki:20160530111536j:plain"
      }}></img></p>
    <p>{`１日目夕食。もっとたくさん料理が出てきました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530111956.jpg",
        "alt": "f:id:yamadagenki:20160530111956j:plain"
      }}></img></p>
    <p>{`2 日目朝食。とても量があって朝からお腹いっぱい！`}</p>
    <h1>{`お楽しみイベントでボーリング`}</h1>
    <p>{`１日目の夜には、近くにあるボーリング場でみんなでボーリングをしました。
トップのスコアは後藤さんの 178。久しぶりにやる人とは思えないスコアでした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530113358.jpg",
        "alt": "f:id:yamadagenki:20160530113358j:plain"
      }}></img></p>
    <p>{`トップのスコアは後藤さんの 178。冷静にすごい`}</p>
    <h1>{`最終成果物発表会`}</h1>
    <p>{`2 日目は、お昼におんやど恵を出発し、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%F2%B2%CF%B8%B6%B1%D8"
      }}>{`湯河原駅`}</a>{`前にある湯河原商工会の会議室を借りてしばらく開発した後、最終成果物を発表しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530113843.jpg",
        "alt": "f:id:yamadagenki:20160530113843j:plain"
      }}></img></p>
    <p>{`湯河原`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%A6%B9%A9%B2%F1%B4%DB"
      }}>{`商工会館`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530131109.jpg",
        "alt": "f:id:yamadagenki:20160530131109j:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/iOS"
      }}>{`iOS`}</a>{`アプリチームの発表`}</p>
    <h1>{`最後に`}</h1>
    <p>{`いつも会社に引きこもってる開発部でしたが、`}</p>
    <p>{`たまには社外で温泉に浸かりながら開発はいい気分転換になりました。`}</p>
    <p>{`また、普段一緒に開発しないメンバーと一緒に開発することで、非常に多くの新しい学びがあり、とても良い合宿となりました。`}</p>
    <p>{`是非、また温泉に行きた。。。`}</p>
    <p>{`じゃなかった、開発合宿行きたい！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160530/20160530114652.jpg",
        "alt": "f:id:yamadagenki:20160530114652j:plain"
      }}></img></p>
    <p>{`2016 年 5 月 28 日(土)株式会社メドレー開発本部@おんやど恵`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      